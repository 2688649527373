import React from 'react';
import SVGIcon from './index';
import PropTypes from 'prop-types';
import colors from '../../../constants/colors';

const IconSaveTemplate = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        fill={colors[props.color]}
        fillRule="evenodd"
        d="M24 15h-2v-5H8v12h7v2H6V6h18v9zm-14-2h2v2h-2v-2zm4 0h6v2h-6v-2zm-4 4h2v2h-2v-2zm4 0h1v2h-1v-2zm3 0h7l3 3v7H17V17zm2 2v2h4v-2h-4zm3 6.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
      />
    </SVGIcon>
  );
};

IconSaveTemplate.propTypes = {
  color: PropTypes.string
};

export default IconSaveTemplate;
