import React from 'react';
import SVGIcon from './index';
import colors from '../../../constants/colors';
import PropTypes from 'prop-types';

const IconChevronLeft = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 24 24">
      <path
        fill={colors[props.color]}
        d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"
      />
      <path d="M0-.5h24v24H0z" fill="none" />
    </SVGIcon>
  );
};

IconChevronLeft.propTypes = {
  color: PropTypes.string
};

export default IconChevronLeft;
