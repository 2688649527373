import styled from "styled-components";
import colors from "../../constants/colors";
import {IconClear, IconLoading, IconSearch} from "../Icons";

export const Location = styled.div`
  line-height: 20px;
  padding: 15px 20px;
  background-color: ${colors.snow};
  cursor: pointer;
`;

export const CommonLocation = styled(Location)`
  color: ${colors.coal};
  font-size: 16px;
`;

export const AutoCompleteWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  .react-autosuggest__container {
    width: 100%;
  }
`;

export const Plus = styled.span`
  position: absolute;
  left: 15px;
  top: 16px;
`;

export const Kilometers = styled.span`
  position: absolute;
  right: 20px;
  top: 16px;
`;

export const RadiusWrapper = styled.div`
  position: relative;

  background-color: ${colors.smoke};
  margin-left: 30px;
  input {
    font-family: GTPressuraPro;
    font-weight: 400;
    font-size: 16px;
    width: 120px;
    padding-left: 27px;
  }
`;

const IconSearch_ = styled(IconSearch)`
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const IconLoading_ = styled(IconLoading)`
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const IconClear_ = styled(IconClear)`
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

export {
    IconClear_ as IconClear,
    IconLoading_ as IconLoading,
    IconSearch_ as IconSearch
};

export const AutoSuggestWrap = styled.div`
  position:relative;
`;

export const Input = styled.input`
  width: 100%;
  height: 60px;
  padding-left: 15px;

  appearance: none;
  border: none;
  border-bottom: 2px solid ${colors.silver};
  background-color: ${colors.smoke};
  color: ${colors.coal};
  line-height: 1.25;
  transition: ${colors.transition};
  font-family: GTPressuraPro, sans-serif;
  font-weight: 400;
  font-size: 16px;

  &:hover {
    border-bottom-color: ${colors.onyx};
  }

  &:focus {
    border-bottom-color: ${colors.traffic};
    outline: none;
  }

  &:disabled {
    background-color: ${colors.silver};
    cursor: not-allowed;

    &:hover {
      border-bottom-color: transparent;
    }
  }
`;
