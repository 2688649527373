import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`  
  .tooltip-helper {
    max-width: 270px!important;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 15px;
    border-radius: 0!important;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style: none;    
  }
  
  .react-autosuggest__container {
    width: 100%;
    position: relative;
  }

  .react-autosuggest__suggestions-container--open {
    position: absolute;
    background-color: var(--white);
    width: 100%;
    z-index: 200;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 0 3px 15px 3px rgba(31, 39, 51, 0.15);
    transform: translateY(6px);
    border: none;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: var(--smoke);
  }
}
`;

export const FormWrapper = styled.section`
  width: 100%;
  padding: 100px;
  background-color: var(--smoke);
`;

export const FormContainer =  styled.div`
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 rgba(31, 39, 51, 0.1);
  padding: 50px 200px;
  max-width: 1170px;
  margin: 0 auto;
  @media(max-width: 1024px) {
    padding: 50px 100px;
  }
`;

export const Header = styled.h3`
  font-size: 32px;
  line-height: 1.25;
  margin: 0 0 25px 0;
  padding: 0;
`;

export const Fields = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
`;

export const Wrapper = styled.div`
  margin-bottom: 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 30px 0 55px auto;
  justify-content: space-between;

  & button {
    &:not(:first-child) {
      margin-left: 38px;
    }
  }
`;

export const DirectionButtons = styled.div`
  &:first-child {
    display: flex;
    align-items: center;
  }
`;
