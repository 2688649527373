import React from 'react';
import styled from 'styled-components'
import colors from '../../../constants/colors'
import Button from '../../../components/Button'
import { numberWithSpaces } from '../helpers';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors.traffic};
  padding: 100px 100px;
`

const Title = styled.div`
  font-family: GTPressuraProBold, sans-serif;
  font-size: 32px;
  line-height: 1.25;
  color: white;
  margin: 0 auto;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 1170px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Tariff = styled.div`
  min-width: 270px;
  width: 24%;
  margin-top: 30px;
  background-color: ${colors.smoke};
  
  &:first-child {
    margin-left: 0;
  }
  
  &:last-child {
  margin-right: 0;
  }
`

const TariffDuration = styled.div`
  width: 100%;
  padding: 30px 0;
  background-color: white;
  line-height: 1.25;
  font-family: GTPressuraProBold, sans-serif;
  font-size: 48px;
  text-align: center;
`

const TariffPrice = styled.div`
  width: 100%;
  padding: 25px 0;
  color: ${colors.coal};
  font-size: 24px;
  text-align: center;
`

const TariffBottom = styled.div`
  margin: 0 auto;
  width: 210px;
  padding-bottom: 30px;
`

function Tariffs() {
  
  const subscriptions = [
    {
      type: "trial",
      duration: "1 день",
      price: 200
    },
    {
      type: "start",
      duration: "7 дней",
      "price": 1000
    },
    {
      type: "base",
      duration: "30 дней",
      price: 3000
    },
    {
      type: "professional",
      duration: "90 дней",
      price: 7000
    }
  ]
  
  return (
    <Wrapper>
      <Container>
        <Title>Тарифы полного доступа к Помощнику формирования цены</Title>
      </Container>
      <Container>
        { subscriptions.map((item, i) => (
          <Tariff key={i}>
            <TariffDuration>
              { item.duration }
            </TariffDuration>
            <TariffBottom>
              <TariffPrice>{ numberWithSpaces(item.price) } ₽</TariffPrice>
              <a href="https://traffic.online/register" target="_blank" rel="noopener noreferrer">
                <Button text="КУПИТЬ ТАРИФ" theme="outline"/>
              </a>
            </TariffBottom>
          </Tariff>
        )) }
      </Container>
    </Wrapper>
  )
}

export default Tariffs;
