import styled from "styled-components";


export const DateRangePickerWrap = styled.div`
  margin: 0 20px 30px 0;
  
  .isOneValue {
    .DateRangePickerInput_arrow,
    .SingleDatePickerInput_arrow {
      visibility: hidden;
    }
  }
`;
