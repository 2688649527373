import axios from "axios";

const version = '/v1'
const API = process.env.API_URL + version;


export function sendMPFormForCalculation(payload) {
  const url = `${API}/market_prices`;
  
  return axios
    .post(url, payload)
    .then((response) => response.data.id);
}

export function getMPCalculationById(id) {
  const url = `${API}/market_prices/${id}`;
  return axios.get(url).then((response) => response.data);
}

export const getLocationByType = (locationType) => (query) => {
  if (query.length === 0 && locationType === 'CITY')
    return _emptySearchImitation();
  
  const params = {
    q: query,
    from_bound: locationType.toLowerCase(),
    to_bound:
      locationType.toLowerCase() === 'city'
        ? 'settlement'
        : locationType.toLowerCase()
  };
  
  if (locationType === 'FEDERAL_DISTRICT') {
    return _getAreas(query);
  }
  
  return _getAddress(params).then((data) =>
    data.items.map((item) => {
      return getCityViewObject(item, item.city_type, item);
    })
  );
};

const _getAddress = async (params) => {
  const url = `${API}/catalog/suggest/address`;
  
  return await axios.get(url, { params, requestId: url }).then(res => res.data);
}

export const getCityViewObject = (city, type, item) => {
  return {
    // label: _getLocality(city, item.settlement, item.settlement_type),
    label: item.value,
    value: {
      ...item,
      label: city,
      type
    }
  };
};

const _emptySearchImitation = () => {
  return new Promise((resolve) => resolve([]));
};

const _getAreas = async () => {
  return [
    {
      name: 'Центральный Федеральный Округ',
      abbrev: 'ЦФО',
      id: 'd151b8ae-8ded-11e9-9ebb-506b8df91bb5'
    },
    {
      name: 'Северо-Западный Федеральный Округ',
      abbrev: 'СЗФО',
      id: '13be0ee0-8dee-11e9-9ebb-506b8df91bb5'
    },
    {
      name: 'Приволжский Федеральный Округ',
      abbrev: 'ПФО',
      id: '3370c5c0-8dee-11e9-9ebb-506b8df91bb5'
    },
    {
      name: 'Уральский Федеральный Округ',
      abbrev: 'УФО',
      id: '6b00ef42-8dee-11e9-9ebb-506b8df91bb5'
    },
    {
      name: 'Южный Федеральный Округ',
      abbrev: 'ЮФО',
      id: '260670a6-8dee-11e9-9ebb-506b8df91bb5'
    },
    {
      name: 'Дальневосточный Федеральный Округ',
      abbrev: 'ДФО',
      id: '4ca95f84-8dee-11e9-9ebb-506b8df91bb5'
    },
    {
      name: 'Северо-Кавказский Федеральный Округ',
      abbrev: 'СКФО',
      id: '59ff6ab6-8dee-11e9-9ebb-506b8df91bb5'
    },
    {
      name: 'Сибирский Федеральный Округ',
      abbrev: 'СФО',
      id: '3ecb15ce-8dee-11e9-9ebb-506b8df91bb5'
    }
  ]
    .map(mapAreaToLocation)
    .map((item) => ({
      label: item.value,
      value: {
        ...item
      }
    }));
}
const mapAreaToLocation = (area) => {
  return {
    country: 'Россия',
    region: '',
    region_type: '',
    region_fias_id: '',
    region_kladr_id: '',
    area: null,
    area_type: area.abbrev,
    area_fias_id: area.id,
    area_kladr_id: null,
    city: '',
    city_type: '',
    city_fias_id: '',
    city_kladr_id: '',
    settlement: null,
    settlement_type: null,
    settlement_fias_id: null,
    settlement_kladr_id: null,
    street: null,
    street_type: null,
    street_fias_id: null,
    street_kladr_id: null,
    house_number: null,
    house_type: null,
    house_fias_id: null,
    house_kladr_id: null,
    block: null,
    block_type: null,
    fias_id: area.id,
    kladr_id: '',
    lat: null,
    lng: null,
    zip_code: null,
    time_zone: null,
    value: area.name
  };
}
