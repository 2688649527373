import React from 'react';
import SVGIcon from './index';
import PropTypes from 'prop-types';
import colors from '../../../constants/colors';

const IconClear = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 24 24">
      <path
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        fill={colors[props.color]}
      />
    </SVGIcon>
  );
};

IconClear.propTypes = {
  color: PropTypes.string
};

export default IconClear;
