/**
 * Маппинг тоннажа/объема в формат react-select
 * @param tonnage
 * @param volume
 * @param values
 * @returns {{label: *, value: {tonnage: *, volume: *}}}
 */
import {
  BODY_TYPE,
  LOADING_LOCATION,
  LOADING_RADIUS, LOADING_TYPE,
  PERIOD_FROM,
  PERIOD_TO, RF_ONLY, SELECTED_LIFTING_CAPACITY, UNLOADING_LOCATION,
  UNLOADING_RADIUS, UNLOADING_TYPE
} from './constants'
import moment from 'moment'
import {REQUEST_DATE_FORMAT} from '../../constants'

export function getMappedLiftingCapacity({ tonnage, volume }) {
  return {
    label: `${tonnage} т / ${volume} м\u00B3`,
    value: {
      tonnage,
      volume,
    }
  };
}


/**
 * Маппинг фейковых данных в обход запроса для демо данных
 * @param state
 * @returns {*}
 */
export function mapStateToFakeDetails(state) {
  return {
    transport_body_type: state[BODY_TYPE],
    tonnage: state[SELECTED_LIFTING_CAPACITY].value.tonnage,
    volume: state[SELECTED_LIFTING_CAPACITY].value.volume,
    from: moment(state[PERIOD_FROM], REQUEST_DATE_FORMAT, true).utcOffset(0,true),
    till: moment(state[PERIOD_TO], REQUEST_DATE_FORMAT, true).utcOffset(0, true),
    rf_only: state[RF_ONLY],
    routes: [
      {
        from_location: {
          fias_id: state[LOADING_LOCATION].value.fias_id,
          federal_district_id: null,
          radius: state[LOADING_RADIUS],
          location: state[LOADING_LOCATION].value,
          location_type: state[LOADING_TYPE],
        },
        to_location: {
          fias_id: state[UNLOADING_LOCATION].value.fias_id,
          federal_district_id: null,
          radius: state[UNLOADING_RADIUS],
          location: state[UNLOADING_LOCATION].value,
          location_type: state[UNLOADING_TYPE],
        },
        min_price: 'XXX XXX',
        avg_price: 'XXX XXX',
        max_price: 'XXX XXX',
        count: state[RF_ONLY] ? "XXX XXX" : "XXX",
      },
    ],
  }
}

/**
 * Маппинг данных формы на запрос
 * @param state
 * @returns {*}
 */
export function mapStateToRequest(state) {
  return {
    transport_body_type: state[BODY_TYPE],
    tonnage: state[SELECTED_LIFTING_CAPACITY].value.tonnage,
    volume: state[SELECTED_LIFTING_CAPACITY].value.volume,
    from: moment(state[PERIOD_FROM], REQUEST_DATE_FORMAT, true).utcOffset(0, true),
    till: moment(state[PERIOD_TO], REQUEST_DATE_FORMAT, true).utcOffset(0, true),
    routes: [
      {
        from_location: {
          fias_id: state[LOADING_LOCATION].value.fias_id,
          federal_district_id: null,
          radius: state[LOADING_RADIUS],
          location: state[LOADING_LOCATION].value,
          location_type: state[LOADING_TYPE],
        },
        to_location: {
          fias_id: state[UNLOADING_LOCATION].value.fias_id,
          federal_district_id: null,
          radius: state[UNLOADING_RADIUS],
          location: state[UNLOADING_LOCATION].value,
          location_type: state[UNLOADING_TYPE],
        }
      }
    ]
  }
}

/**
 * Маппинг данных из запроса деталей рыночных цен на форму
 * @param details
 * @returns {*}
 */
export function mapDetailsToForm(details) {
  const { from, till, routes, tonnage, volume, transport_body_type, rf_only } = details
  return {
    [SELECTED_LIFTING_CAPACITY]: { label: `${tonnage} т / ${volume} м\u00B3`, value: { tonnage, volume } },
    [BODY_TYPE]: transport_body_type,
    [RF_ONLY]: !!rf_only,
    [LOADING_TYPE]: routes[0].to_location.location_type,
    [LOADING_LOCATION]: { label: routes[0].to_location.location.value, value: routes[0].to_location.location },
    [LOADING_RADIUS]: routes[0].to_location.radius,
    [UNLOADING_TYPE]: routes[0].from_location.location_type,
    [UNLOADING_LOCATION]: { label: routes[0].from_location.location.value, value: routes[0].from_location.location },
    [UNLOADING_RADIUS]: routes[0].from_location.radius,
    [PERIOD_FROM]: moment(from).subtract(8, 'd').format(REQUEST_DATE_FORMAT),
    [PERIOD_TO]: moment(till).subtract(1, 'd').format(REQUEST_DATE_FORMAT),
  }
}

/**
 * Разделение числа пробелами по разрядам
 * @param num
 * @returns string
 */
export function numberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
