import React, {Component} from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import {TrendsFooter} from '../components/FooterCollection'
import SEO from '../components/Seo'
import TrendsHeader from '../components/HeadersCollection/TrendsHeader'
import {TrendsContentBlock} from '../components/ContentCollection'
import MarketPriceForm from '../containers/MarketPriceForm'
import IconCard from '../components/IconCard'
import Tariffs from '../containers/MarketPriceForm/Components/Tariffs'
import TitleWithButton from '../containers/MarketPriceForm/Components/TitleWithButton'
import { isLegacyBrowser } from '../helpers/browserTools';

const List = styled.div``

const ListItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  &:before {
    content: "\\2022";
    margin-right: 10px;
    font-size: 20px;
  }
`

class MarketPricesPage extends Component {
  constructor() {
    super();
    
    this.marketPriceRef = React.createRef()
    this.aboutRef = React.createRef()
  }
  
  scrollToRef = (ref) => {
    setTimeout(() => {
      
      if (isLegacyBrowser) {
        window.scrollTo(0, ref.current.offsetTop);
      } else {
        window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })
      }
    }, 32)
  }
  
  render() {
    
    return (
      <Layout>
        <SEO
          title={'Рыночные цены'}
          keywords={[`traffic`, `траффик`, `грузоперевозки`]}
          description={
            'Расчёт рыночных цен – это инструмент комплексного мониторинга рынка, который позволяет на системной основе оперативно отслеживать еженедельное изменение рыночных цен на автомобильные перевозки грузов по ключевым направлениям в России.'
          }
        />
        <TrendsHeader/>
        <main>
          <TrendsContentBlock
            title={<>Помощник формирования<br/>цены</>}
            subtitle="Первый в России инструмент объективного online отслеживания рыночных цен на автомобильные перевозки. Сервис собирает рыночные цены по фактической стоимости перевозок из десятков источников и обрабатывает их технологиями BigData."
            action="Рассчитать"
            about="Подробнее"
            onAction={() => this.scrollToRef(this.marketPriceRef)}
            onAbout={() => this.scrollToRef(this.aboutRef)}
            image="/images/trends/group-5.png"
            containerStyle={{
              backgroundSize: 770,
              minHeight: '31.5rem',
              backgroundPosition: 'right bottom'
            }}
          />
          <div ref={this.marketPriceRef}>
            <MarketPriceForm onAction={() => this.scrollToRef(this.marketPriceRef)}/>
          </div>
          <Tariffs/>
          <div
            className="container"
            style={{paddingTop: '120px'}}
            ref={this.aboutRef}
          >
            <IconCard
              title="Преимущества сервиса"
              text={
                <List>
                  <ListItem>
                    <span><b>масштабно:</b> анализируется более 600 000 тарифов по перевозкам в месяц;</span>
                  </ListItem>
                  <ListItem>
                    <span><b>объективно:</b> информация собирается из десятков независимых источников;</span>
                  </ListItem>
                  <ListItem>
                    <span><b>просто:</b> данные о рыночных ценах всегда доступны в 1 клик;</span>
                  </ListItem>
                  <ListItem>
                    <span><b>актуально:</b> онлайн информация про тарифы по любому направлению грузоперевозок по России;</span>
                  </ListItem>
                  <ListItem>
                    <span><b>быстро:</b> доступ к сервису 24/7 365 дней в году по ссылке с возможностью интеграции;</span>
                  </ListItem>
                  <ListItem>
                    <span><b>гибко:</b> вы сами выбирает интересующий период времени, географию и радиусы погрузки и выгрузки.</span>
                  </ListItem>
                </List>
              }
            />
            <IconCard
              title="Что вы получаете"
              text={
                <List>
                  <ListItem>
                    понимание коридора цен и средней цены на рынке в режиме онлайн;
                  </ListItem>
                  <ListItem>
                    возможность отследить динамику изменения цены во времени;
                  </ListItem>
                  <ListItem>
                    возможность оперативно управлять маржинальностью бизнеса;
                  </ListItem>
                  <ListItem>
                    возможность обосновать уровень или изменение цен на перевозки;
                  </ListItem>
                  <ListItem>
                    возможность определять выгодные направления перевозок;
                  </ListItem>
                  <ListItem>
                    объективные и надежные данные для построения системы ценообразования.
                  </ListItem>
                </List>
              }
              isRightAlign
            />
          </div>
        </main>
        <TitleWithButton onAction={() => this.scrollToRef(this.marketPriceRef)}/>
        <TrendsFooter
          title={
            <span>
            Traffic — провайдер грузоперевозок <br/> по России
          </span>
          }
          subtitle={
            <span>
            Цифровая логистическая платформа для владельцев грузов и
            транспортных компаний
          </span>
          }
        />
      </Layout>
    )
  }
}

export default MarketPricesPage;
