import React, { Component } from "react";
import PropTypes from 'prop-types';
import moment from 'moment'
import {
    ButtonWrapper,
    DirectionButtons,
    Fields,
    FormWrapper,
    FormContainer,
    Header,
    Wrapper
} from "./styles";
import { GlobalStyles } from "../../common/styles";
import BodyTypeSwitcher from "../../components/BodyTypeSwitcher";
import {getLocationByType, getMPCalculationById, sendMPFormForCalculation} from './services'
import DateRangePicker  from "../../components/DateRangePicker";
import Switcher  from "../../components/Switcher";
import Label from "../../components/Label";
import CheckBoxInput  from "../../components/CheckBoxInput";
import Button from "../../components/Button";
import { ButtonWithIcon } from "../../components/ButtonsCollection";
import 'react-dates/initialize';
import AutocompleteWithRadius from "../../components/AutoComplete/AutocompleteWithRadius";
import {IconAddCircle, IconListOrder, IconLoading} from '../../components/Icons'
import {SimpleDropdown} from '../../components/DropdownCollection'
import {Select} from '../../components/TonnageVolume/styles'
import {isInclusivelyAfterDay, isInclusivelyBeforeDay} from 'react-dates'
import {
  defaultFormValues,
  switcherOptions,
  BODY_TYPE,
  LOADING_LOCATION,
  LOADING_RADIUS,
  LOADING_TYPE,
  PERIOD_FROM,
  PERIOD_TO,
  SELECTED_LIFTING_CAPACITY,
  UNLOADING_LOCATION,
  UNLOADING_RADIUS,
  UNLOADING_TYPE,
  RF_ONLY,
  demoMoscowFromLocation,
  demoSpbToLocation
} from './constants'
import NotificationBar from '../../components/NotificationBar'
import {getMappedLiftingCapacity, mapStateToFakeDetails, mapStateToRequest} from './helpers'
import Details from './Components/Details'

const LIFTING_CAPACITY = [
  { tonnage: 1.5, volume: 9 },
  { tonnage: 3, volume: 16 },
  { tonnage: 5, volume: 36 },
  { tonnage: 10, volume: 54 },
  { tonnage: 20, volume: 82 }
].map(getMappedLiftingCapacity);

class MarketPriceForm extends Component {

  static propTypes ={
    onAction: PropTypes.func
  };
  
  constructor() {
    super();
    this.state = {
      ...defaultFormValues,
      loading: false,
      details: undefined,
    };
  }
  
  setDefaultFormState = () => {
    const {loading, details} = this.state;
    this.setState({
      ...defaultFormValues, loading, details
    })
  }
  
  handleData = type => value => {
    this.setState({ [type]: value })
  }
  
  handleLocationTypeChange = direction => value => {
    if (direction === 'from') {
      if (value === this.state[LOADING_TYPE]) return
      
      this.setState({
        [LOADING_TYPE]: value,
        [LOADING_LOCATION]: value === 'CITY' ? demoMoscowFromLocation : null,
      })
    } else {
      if (value === this.state[UNLOADING_TYPE]) return
      
      this.setState({
        [UNLOADING_TYPE]: value,
        [UNLOADING_LOCATION]: value === 'CITY' ? demoSpbToLocation : null,
      })
    }
  }
  
  handleOnFormSubmit = (e) => {
    e.preventDefault()
    this.calculateMarketPrices(this.state)
  };
  
  calculateMarketPrices = async (state) => {
    this.setState({ loading: true })
    if (
      state[LOADING_TYPE] !== 'CITY' || state[UNLOADING_TYPE] !== 'CITY' ||
      state[LOADING_LOCATION].value.fias_id !== '0c5b2444-70a0-4932-980c-b4dc0d3f02b5' ||
      state[UNLOADING_LOCATION].value.fias_id !== 'c2deb16a-0330-4f05-821f-1d09c93331e6' ||
      state[RF_ONLY]
    ) {
      await new Promise((resolve => {
        setTimeout(resolve, 1300)
      }))
      this.setState({ details: mapStateToFakeDetails(state), loading: false })
      this.props.onAction();
      return;
    }
    
    const payload = mapStateToRequest(state)
    const calcID = await sendMPFormForCalculation(payload);
    const details = await getMPCalculationById(calcID)
    
    if (details) {
      this.setState({ details, loading: false })
      this.props.onAction();
    }
  }
  
  resetForm = () => {
    this.setState({
      ...defaultFormValues,
      loading: false,
      details: undefined,
    })
  }
  
  editForm = () => {
    this.setState({
      ...this.state, details: undefined,
    })
  }
  
  renderForm = () => {
    return (
      <>
        <GlobalStyles/>
        <Header>Сформируйте запрос для расчёта рыночных цен</Header>
        <form autoComplete="off" onSubmit={this.handleOnFormSubmit}>
          <BodyTypeSwitcher
            value={this.state[BODY_TYPE]}
            onChange={this.handleData(BODY_TYPE)}
          />
          <Fields style={{ alignItems: "start" }}>
            <Select>
              <Label text={"Тоннаж / Объём"}/>
              <SimpleDropdown
                options={LIFTING_CAPACITY}
                onSelect={option => this.handleData(SELECTED_LIFTING_CAPACITY)(option)}
                value={this.state[SELECTED_LIFTING_CAPACITY].value}
                isSearchable={false}
                placeholder=""
              />
            </Select>
            <DateRangePicker
              startDate={this.state[PERIOD_FROM]}
              endDate={this.state[PERIOD_TO]}
              startDateId="startDateId"
              endDateId="endDateId"
              onChange={(startDate, endDate) => this.setState({ [PERIOD_FROM]: startDate, [PERIOD_TO]: endDate })}
              customArrowIcon="—"
              startDatePlaceholderText="Период"
              showClearDates={false}
              isOutsideRange={(day) =>
                isInclusivelyBeforeDay(day, moment('2018-07-31')) ||
                isInclusivelyAfterDay(day, moment())
              }
              required={true}
            />
            <Fields style={{ marginTop: 25, height: 60, marginBottom: 30 }}>
              <CheckBoxInput
                onChange={this.handleData(RF_ONLY)}
                checked={this.state[RF_ONLY]}
                text="По РФ"
                hint="Диапазон рыночных цен на грузоперевозки по России. При выборе этого параметра блок направлений будет скрыт"
              />
            </Fields>
          </Fields>
          { !this.state[RF_ONLY] &&
            <>
              <Wrapper>
                <Label text="Откуда" />
                  <Switcher
                    options={switcherOptions}
                    value={this.state[LOADING_TYPE]}
                    onChange={this.handleLocationTypeChange("from")}
                  />
              </Wrapper>
              <Wrapper>
                <AutocompleteWithRadius
                  onSearch={getLocationByType(this.state[LOADING_TYPE])}
                  inputValueRequired={true}
                  onCityChange={this.handleData(LOADING_LOCATION)}
                  onRadiusChange={(e) => this.handleData(LOADING_RADIUS)(e.target.value)}
                  radiusValue={this.state[LOADING_RADIUS]}
                  autocompleteValue={this.state[LOADING_LOCATION]}
                  radiusDisabled={this.state[LOADING_TYPE] !== 'CITY'}
                  radiusMinMax={{ min: 5, max: 500 }}
                  placeholder="Откуда"
                />
              </Wrapper>
              <Wrapper>
                <Label text="Куда" />
                <Switcher
                  options={switcherOptions}
                  value={this.state[UNLOADING_TYPE]}
                  onChange={this.handleLocationTypeChange("to")}
                />
              </Wrapper>
              <Wrapper>
                <AutocompleteWithRadius
                  onSearch={getLocationByType(this.state[UNLOADING_TYPE])}
                  inputValueRequired={true}
                  onCityChange={this.handleData(UNLOADING_LOCATION)}
                  onRadiusChange={(e) => this.handleData(UNLOADING_RADIUS)(e.target.value)}
                  radiusValue={this.state[UNLOADING_RADIUS]}
                  autocompleteValue={this.state[UNLOADING_LOCATION]}
                  radiusDisabled={this.state[UNLOADING_TYPE] !== 'CITY'}
                  radiusMinMax={{ min: 5, max: 500 }}
                  placeholder="Куда"
                />
              </Wrapper>
              <ButtonWrapper>
                <DirectionButtons>
                  <ButtonWithIcon
                    color="traffic"
                    icon={<IconAddCircle width="24" height="24"/>}
                    disabled={true}
                  >
                    Добавить направление
                  </ButtonWithIcon>
                  <ButtonWithIcon
                    color="traffic"
                    icon={<IconListOrder width="24" height="24"/>}
                    disabled={true}
                  >
                    Шаблоны направлений
                  </ButtonWithIcon>
                </DirectionButtons>
                <ButtonWithIcon color="traffic" type="button" onClick={this.resetForm}>Сбросить фильтры</ButtonWithIcon>
              </ButtonWrapper>
            </>
          }
          <Button
            type="submit"
            disabled={this.state.loading}
            size="largest"
            text={ this.state.loading ? <IconLoading width="24" height="24" color="traffic"/> : "Сформировать список рыночных цен" }
          />
        </form>
      </>
    );
  };

  render() {
    const { details } = this.state;
    
    return (
      <FormWrapper>
        <div style={{marginBottom: 20 }}>
          <NotificationBar
            title="Вам доступна демо-версия инструмента"
            content="В демо-версии значения расчёта можно получить только для направления Москва → Санкт-Петербург. Также ограничены функции добавления направлений, использования шаблонов и скачивания отчётов в xls-файл. Пройдите короткую регистрацию и получите полный доступ, чтобы увидеть значения расчётов по любым направлениям."
            actionButton={
              <a href="https://traffic.online/register" target="_blank" rel="noopener noreferrer">
                <Button size="medium" text="Получить полный доступ" theme="coral" onClick={() => {}}/>
              </a>
            }
          />
        </div>
          { !details
            ? <FormContainer> {this.renderForm()} </FormContainer>
            : <Details reset={this.resetForm} details={details} editForm={this.editForm}/>
          }
      </FormWrapper>
    );
  }
}

export default MarketPriceForm;
