// import {getMappedLiftingCapacity} from './helpers'
import moment from 'moment'
import {REQUEST_DATE_FORMAT} from '../../constants'

export const BODY_TYPE = 'BODY_TYPE'
export const LIFTING_CAPACITY = 'LIFTING_CAPACITY'
export const SELECTED_LIFTING_CAPACITY = 'SELECTED_LIFTING_CAPACITY'
export const RF_ONLY = 'RF_ONLY'
export const PERIOD_FROM = 'PERIOD_FROM'
export const PERIOD_TO = 'PERIOD_TO'

export const LOADING_TYPE = 'LOADING_TYPE'
export const UNLOADING_TYPE = 'UNLOADING_TYPE'

export const LOADING_RADIUS = 'LOADING_RADIUS'
export const UNLOADING_RADIUS = 'UNLOADING_RADIUS'

export const LOADING_LOCATION = 'LOADING_LOCATION'
export const UNLOADING_LOCATION = 'UNLOADING_LOCATION'

export const demoMoscowFromLocation = {
  label: 'г Москва, РФ',
  value: {
    country: 'Россия',
    city: 'г Москва, РФ',
    city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    value: 'г Москва, РФ'
  }
};

export const demoSpbToLocation = {
  label: 'г Санкт-Петербург, РФ',
  value: {
    country: 'Россия',
    city: 'г Санкт-Петербург, РФ',
    city_fias_id: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
    fias_id: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
    value: 'г Санкт-Петербург, РФ',
  },
};

export const switcherOptions = [
  { value: "CITY", label: "Населённый пункт" },
  { value: "REGION", label: "Субъект РФ" },
  { value: "FEDERAL_DISTRICT", label: "Округ" },
]

export const defaultFormValues = {
  [SELECTED_LIFTING_CAPACITY]: { label: '20 т / 82 м\u00B3', value: { tonnage: 20, volume: 82 } },
  [BODY_TYPE]: "awning",
  [RF_ONLY]: false,
  [LOADING_TYPE]: "CITY",
  [LOADING_LOCATION]: demoMoscowFromLocation,
  [LOADING_RADIUS]: "50",
  [UNLOADING_TYPE]: "CITY",
  [UNLOADING_LOCATION]: demoSpbToLocation,
  [UNLOADING_RADIUS]: "50",
  [PERIOD_FROM]: moment().subtract(8, 'd').format(REQUEST_DATE_FORMAT),
  [PERIOD_TO]: moment().subtract(1, 'd').format(REQUEST_DATE_FORMAT),
}
