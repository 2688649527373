import React from 'react';
import SVGIcon from './index';
import colors from '../../../constants/colors';
import PropTypes from 'prop-types';

const IconDatePicker = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 30 30">
      <path
        d="m21 6h3v18h-18v-18h3v-2h2v2h8v-2h2zm-13 5v11h14v-11z"
        fill={colors[props.color]}
        fillRule="evenodd"
      />
    </SVGIcon>
  );
};
IconDatePicker.propTypes = {
  color: PropTypes.string
};
export default IconDatePicker;
