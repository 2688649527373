import { createGlobalStyle } from "styled-components";
import colors from "../constants/colors";

const {boxShadowModal, coal, coral, silver, smoke, snow, traffic} = colors;
const transition = 'all 240ms ease';

export const GlobalStyles = createGlobalStyle`
  .tooltip-helper {
    max-width: 270px!important;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 15px;
    border-radius: 0!important;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .react-autosuggest__container {
    width: 100%;
    position: relative;
  }

  .react-autosuggest__suggestions-container--open {
    position: absolute;
    background-color: var(--white);
    width: 100%;
    z-index: 200;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 0 3px 15px 3px rgba(31, 39, 51, 0.15);
    transform: translateY(6px);
    border: none;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: var(--smoke);
  }
  
  // стили для datarangepicker
  
  .SingleDatePicker,
  .DateRangePicker {
    position: relative;
    display: flex;
    width: 250px;
    height: 60px;
    
    & > div {
      width: 100%;
    }
  }
  
  .DateRangePickerInput,
  .SingleDatePickerInput {
    font-family: GTPressuraPro, sans-serif;
    border-bottom: 2px solid ${silver};
    border-bottom-color: ${silver};
    background-color: ${smoke};
    color: ${coal};
    line-height: 1.25;
    transition: ${transition};
    height: 100%;
    width: 100%;
  }
  
  .SingleDatePickerInput {
    display: flex;

    .DateInput {
      display: inline-block;
      
      input {
        padding: 0;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        background-color: #f5f8fc;
        text-align: center;
      }
      
      .DateInput_screenReaderMessage {
        display: none;
      }
    }
  }

  &:hover {
    .DateRangePickerInput:not(.DateRangePickerInput__disabled),
    .SingleDatePickerInput:not(.SingleDatePickerInput__disabled) {
      border-bottom-color: ${silver};
    }
  }
  
  .DateInput {
    height: 100%;
    width: 100%;

    input {
      height: 100%;
      padding: 0;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      background-color: ${smoke};
      text-align: center;

      &:focus {
        border: none;
      }
    }

  .DateInput_fang {
      display: none;
    }
  }
  
  .DateInput__small {
    width: 65px;
    margin: 0 5px;
    display: inline-block;
    height: 35px;
    
    .DateInput_screenReaderMessage {
      display: none;
    }
  }
  
  .DateRangePickerInput_arrow,
  .SingleDatePickerInput_arrow {
    width: 5px;
    display: inline-flex;
    align-items: center;;
    height: 35px;
  }
  
  .DateRangePickerInput_calendarIcon,
  .SingleDatePickerInput_calendarIcon {
    padding: 0;
    padding-left: 10px;
    margin: 0;
    height: 100%;
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
  }
  
  .DateRangePickerInput_clearDates,
  .SingleDatePickerInput_clearDates {
    right: 15px;
    padding: 0;
    margin: 0;
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .DateRangePickerInput__showClearDates,
  .SingleDatePickerInput__showClearDates {
    padding-right: 0;
  }
  
  .DateRangePickerInput__disabled,
  .SingleDatePickerInput__disabled,
  .DateInput__disabled input {
    background-color: ${silver};
    cursor: not-allowed;
  }
  
  button.DateRangePickerInput_calendarIcon:focus,
  button.SingleDatePickerInput_calendarIcon:focus {
    border: none;
    outline: none;
  }
  
  .DateInput_input,
  .DateInput_input__focused {
    font-family: GTPressuraPro, Arial;
    border-bottom: none;
    font-weight: 200;
    font-size: 19px;
    line-height: 24px;
    color: ${coal};
    background-color: ${snow};
    width: 100%;
    padding: 11px 11px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  
  &.isEmpty {
    .DateInput__small:first-of-type {
      width: 146px;

      input {
        text-align: left;
      }
    }
  
    .DateInput__small:last-of-type {
      display: none;
    }
  
    .DateRangePickerInput_arrow {
      display: none;
    }
  
    .DateRangePickerInput__showClearDates,
    .SingleDatePickerInput__showClearDates {
      padding-right: 0;
    }
  }
  
  &.isOneValue {
    .DateRangePickerInput_arrow,
    .SingleDatePickerInput_arrow {
      visibility: hidden;
    }
  }
  
  .DateRangePickerInput_clearDates:focus,
  .SingleDatePickerInput_clearDates:focus {
    border: none;
    outline: none;
  }
  
  .iconClear {
    margin-top: 5px;
    transition: ${transition};
  
    fill: ${silver};
  
  &:hover,
  &:focus {
      fill: ${coral};
    }
  }
  
  .datePicker {
  .DateInput__small {
      width: 100%;
      margin: 0 5px;
    }
  }
  
  .monthYearWrapper {
    display: flex;
    justify-content: center;
  
    select {
      background-color: transparent;
      border: none;
      text-transform: capitalize;
    }
  }
  
  .DayPicker_weekHeaders {
    position: relative;
  }
  .DayPicker_weekHeaders__horizontal {
    margin-left: 9px;
  }
  .DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 60px;
    z-index: 2;
    text-align: left;
  }
  .DayPicker_weekHeader__vertical {
    left: 50%;
  }
  .DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: ${snow};
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
  }
  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
  }
  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 0;
  }
  .DayPicker_transitionContainer__horizontal {
    transition: ${transition};
  }
  .DayPicker_transitionContainer__vertical {
    width: 100%;
  }
  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll;
  }
  
  .DayPickerKeyboardShortcuts_show {
  width: 22px;
  position: absolute;
  z-index: 2;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight:hover {
    border-right: 33px solid #008489;
  }
  .DayPickerKeyboardShortcuts_show__topRight {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__topRight:hover {
    border-right: 33px solid #008489;
  }
  .DayPickerKeyboardShortcuts_show__topLeft {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0;
  }
  .DayPickerKeyboardShortcuts_show__topLeft:hover {
    border-left: 33px solid #008489;
  }
  .DayPickerKeyboardShortcuts_showSpan {
    color: ${snow};
    position: absolute;
  }
  .DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: -28px;
  }
  .DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: -28px;
  }
  .DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: -28px;
  }
  .DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: ${snow};
    border-radius: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
  }
  .DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  .DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px;
  }
  .DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2;
  }
  .DayPickerKeyboardShortcuts_close:active {
    outline: 0;
  }
  .DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: ${silver};
  }
  .DayPickerKeyboardShortcuts_closeSvg:focus,
  .DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: ${silver};
  }
  .CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    border-radius: 0;
  }
  .CalendarDay:active {
    outline: 0;
  }
  .CalendarDay__defaultCursor {
    cursor: default;
  }
  .CalendarDay__default {
    color: ${coal};
    background: ${snow};
  }
  .CalendarDay__default:hover {
    background: #e4e7e7;
    color: inherit;
  }
  .CalendarDay__hovered_offset {
    background: #f4f5f5;
    color: inherit;
  }
  .CalendarDay__outside {
    background: ${snow};
    color: ${coal};
  }
  .CalendarDay__blocked_minimum_nights {
    background: ${snow};
    color: ${silver};
  }
  .CalendarDay__blocked_minimum_nights:active,
  .CalendarDay__blocked_minimum_nights:hover {
    background: ${snow};
    color: ${silver};
  }
  .CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: ${coal};
  }
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: ${coal};
  }
  .CalendarDay__today {
    background-color: ${smoke};
  }
  .CalendarDay__selected_span {
    background: ${traffic};
    color: ${snow};
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${traffic};
    color: ${snow};
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${traffic};
    color: ${snow};
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${traffic};
    color: ${snow};
  }
  .CalendarDay__hovered_span:active {
    background: ${traffic};
    color: ${snow};
  }
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: ${silver};
    color: #82888a;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: ${snow};
    color: ${silver};
  }
  .CalendarMonth {
    background: ${snow};
    text-align: center;
    vertical-align: top;
    user-select: none;
  }
  .CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  .CalendarMonth_verticalSpacing {
    border-collapse: separate;
  }
  .CalendarMonth_caption {
    color: ${coal};
    font-size: 16px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial;
    text-transform: capitalize;
  }
  .CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px;
  }
  .CalendarMonthGrid {
    background: ${snow};
    text-align: left;
    z-index: 0;
  }
  .CalendarMonthGrid__animating {
    z-index: 1;
  }
  .CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px;
  }
  .CalendarMonthGrid__vertical {
    margin: 0 auto;
  }
  .CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll;
  }
  .CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%;
  }
  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }
  .CalendarMonthGrid_month__hidden {
    visibility: hidden;
  }
  .DayPickerNavigation {
    position: relative;
    z-index: 2;
  }
  .DayPickerNavigation__horizontal {
    height: 0;
  }
  .DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0;
  }
  .DayPickerNavigation__verticalScrollableDefault {
    position: relative;
  }
  .DayPickerNavigation_button {
    cursor: pointer;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .DayPickerNavigation_button__default {
    background-color: ${snow};
    color: #757575;
  }
  .DayPickerNavigation_button__default:active {
    background: ${silver};
  }
  .DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 12px;
    line-height: 0.78;
    border-radius: 0;
    padding: 6px 9px;
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px;
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px;
  }
  .DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: ${snow};
    box-shadow: ${boxShadowModal};
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%;
  }
  .DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0;
  }
  .DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%;
  }
  .DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block;
  }
  .DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: ${coal};
  }
  .DayPicker {
    background: ${snow};
    position: relative;
    text-align: left;
    z-index: 1;
  }
  .DayPicker__horizontal {
    background: ${snow};
  }
  .DayPicker__verticalScrollable {
    height: 100%;
  }
  .DayPicker__hidden {
    visibility: hidden;
  }
  .DayPicker__withBorder {
    box-shadow: ${boxShadowModal};
    border-radius: 0;
  }
  .DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .DayPicker_portal__vertical {
    position: initial;
  }
  .DayPicker_focusRegion {
    outline: 0;
  }
  .DayPicker_calendarInfo__horizontal,
  .DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top;
  }
`;
