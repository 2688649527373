import React from 'react';
import SVGIcon from './index';
import colors from '../../../constants/colors';
import PropTypes from 'prop-types';

const IconDownloadFile = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 24 24">
      <path
        d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"
        fill={colors[props.color]}
      />
    </SVGIcon>
  );
};

IconDownloadFile.propTypes = {
  color: PropTypes.string
};

export default IconDownloadFile;
