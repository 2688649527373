import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Label from "../Label";
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import moment from 'moment';
import ruLocale from 'moment/locale/ru';
import { DateRangePickerWrap } from './styles';

import { SHORT_DATE_FORMAT, REQUEST_DATE_FORMAT } from '../../constants';
import {
  IconChevronLeft,
  IconChevronRight,
  IconClear,
  IconDatePicker
} from '../Icons';

/**
 * Валидация значения для state
 * @param rawValue
 * @returns {null}
 */
function getValueForState(rawValue) {
  return rawValue && rawValue.isValid() ? rawValue : null;
}

/**
 * Валидация и маппинг значения для props
 * @param rawValue
 * @param dateFormat
 * @returns {null}
 */
function getValueForProps(rawValue, dateFormat) {
  return rawValue && rawValue.isValid() ? rawValue.format(dateFormat) : null;
}

class DatePickerRange extends Component {
  constructor(props) {
    super(props);

    const { externalDateFormat } = props;

    const startDate = moment(this.props.startDate, externalDateFormat, true);
    const endDate = moment(this.props.endDate, externalDateFormat, true);

    this.state = {
      focusedInput: null,
      startDate: getValueForState(startDate),
      endDate: getValueForState(endDate)
    };
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, externalDateFormat } = this.props;

    if (prevProps.startDate !== startDate) {
      this.setState({
        startDate: getValueForState(moment(startDate, externalDateFormat, true))
      });
    }

    if (prevProps.endDate !== endDate) {
      this.setState({
        endDate: getValueForState(moment(endDate, externalDateFormat, true))
      });
    }
  }

  /**
   * Событие при смене дат
   * @param startDate
   * @param endDate
   */
  onDatesChange = ({ startDate, endDate }) => {
    if ((startDate && !startDate.isSame(this.state.startDate)) || !startDate) {
      this.valuesDirty = true;
    }

    if ((endDate && !endDate.isSame(this.state.endDate)) || !endDate) {
      this.valuesDirty = true;
    }

    this.setState({ startDate, endDate }, this.afterStateChange);
  };

  /**
   * Callback на обновление state
   */
  afterStateChange = () => {
    const { startDate, endDate, focusedInput } = this.state;
    const { externalDateFormat } = this.props;

    // запускаем onChange, если дата изменилась или был закрыт календарь
    // подробнее - http://www.bryanhadro.com/2018/05/04/final-ondateschange-with-react-dates/
    if (!focusedInput && this.valuesDirty) {
      this.valuesDirty = false;
      this.props.onChange(
        getValueForProps(startDate, externalDateFormat),
        getValueForProps(endDate, externalDateFormat)
      );
    }
  };

  /**
   * Событие фокуса
   * @param focusedInput
   */
  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput }, this.afterStateChange);
  };

  render() {
    const {
      dateFormat,
      startDateId,
      endDateId,
      startDatePlaceholderText,
      anchorDirection,
      isOutsideRange,
      showClearDates,
      required,
    } = this.props;
    const { focusedInput, startDate, endDate } = this.state;

    const isEmptyValue = !startDate && !endDate;
    const isOneValue = !startDate || !endDate;
  
    moment.updateLocale('ru', ruLocale)

    return (
      <DateRangePickerWrap
        isEmpty={isEmptyValue}
        isOneValue={isOneValue}
        focusedInput={focusedInput}
      >
        <Label text="Период данных" />
        <DateRangePicker
          startDate={startDate}
          startDateId={startDateId}
          endDate={endDate}
          endDateId={endDateId}
          onDatesChange={this.onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={this.onFocusChange}
          displayFormat={dateFormat}
          showClearDates={showClearDates}
          customCloseIcon={
            <IconClear
              width="18px"
              height="18px"
              color="onyx"
            />
          }
          startDatePlaceholderText={
            isEmptyValue ? startDatePlaceholderText : ''
          }
          endDatePlaceholderText=""
          hideKeyboardShortcutsPanel
          noBorder
          required={required}
          small
          readOnly
          minimumNights={0}
          inputIconPosition="before"
          customInputIcon={
            <IconDatePicker width="30px" height="30px" color="onyx" />
          }
          renderMonthElement={({ month }) =>
            `${month.format('MMMM')} ${month.year()}`
          }
          anchorDirection={anchorDirection}
          navPrev={
            <div
              role="button"
              tabIndex="0"
              className={classnames(
                'DayPickerNavigation_button__horizontal',
                'DayPickerNavigation_button__horizontalDefault',
                'DayPickerNavigation_leftButton__horizontalDefault'
              )}
              aria-label="Переход к предыдущему месяцу"
            >
              <IconChevronLeft width="24px" height="24px" color="onyx" />
            </div>
          }
          navNext={
            <div
              role="button"
              tabIndex="0"
              className={classnames(
                'DayPickerNavigation_button__horizontal',
                'DayPickerNavigation_button__horizontalDefault',
                'DayPickerNavigation_rightButton__horizontalDefault'
              )}
              aria-label="Переход к следующему месяцу"
            >
              <IconChevronRight width="24px" height="24px" color="onyx" />
            </div>
          }
          verticalSpacing={26}
          monthFormat="MMMM"
          isOutsideRange={isOutsideRange}
        />
      </DateRangePickerWrap>
    );
  }
}

DatePickerRange.propTypes = {
  onChange: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  dateFormat: PropTypes.string,
  externalDateFormat: PropTypes.string,
  startDatePlaceholderText: PropTypes.string,
  startDateId: PropTypes.string,
  endDateId: PropTypes.string,
  anchorDirection: PropTypes.string,
  isOutsideRange: PropTypes.func,
  className: PropTypes.string,
  showClearDates: PropTypes.bool,
  required: PropTypes.bool,
};

DatePickerRange.defaultProps = {
  startDate: null,
  endDate: null,
  // формат отображение даты на UI
  dateFormat: SHORT_DATE_FORMAT,
  // формат нормализованной даты
  externalDateFormat: REQUEST_DATE_FORMAT,
  anchorDirection: 'right',
  showClearDates: true,
  /**
   * условие для возможности выбора прошедших дат
   * по умолчанию только будущие даты:
   * условие из исходников:
   * https://github.com/airbnb/react-dates/blob/master/src/components/DateRangePicker.jsx#L110
   */
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment())
};

export default DatePickerRange;
