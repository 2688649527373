import React from 'react';
import SVGIcon from './index';
import colors from '../../../constants/colors';
import PropTypes from 'prop-types';

const IconChevronRight = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 24 24">
      <path
        fill={colors[props.color]}
        d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"
      />
      <path d="M0-.25h24v24H0z" fill="none" />
    </SVGIcon>
  );
};

IconChevronRight.propTypes = {
  color: PropTypes.string
};

export default IconChevronRight;
