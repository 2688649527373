import React from 'react';
import SVGIcon from './index';
import PropTypes from 'prop-types';
import colors from '../../../constants/colors';

const IconListOrder = (props) => {
  return (
    <SVGIcon {...props} viewBox="0 0 24 24">
      <path
        clipRule="evenodd"
        d="m3 3h18v18h-18zm2 4v12h14v-12zm2 3h2v2h-2zm4 0h6v2h-6zm-4 4h2v2h-2zm4 0h6v2h-6z"
        fillRule="evenodd"
        fill={colors[props.color]}
      />
    </SVGIcon>
  );
};

IconListOrder.propTypes = {
  color: PropTypes.string
};

export default IconListOrder;
