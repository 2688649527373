import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import colors from "../../constants/colors";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
  border: 2px solid ${colors.traffic};
  padding: 20px 30px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: GTPressuraPro, sans-serif;
  font-size: 20px;
  line-height: 1.25;
  color: ${colors.coal};
  margin-bottom: 5px;
`;

const Content = styled.div`
  font-size: 16px;
  line-height: 1.25;
  color: ${colors.coal};
  opacity: 0.6;
`;

function NotificationBar(props) {
  const { title, actionButton, content } = props;

  return (
    <Container>
      <div>
        <Title>{title}</Title>
        <Content>{content}</Content>
      </div>
      <div style={{ minWidth: 270 }}>{actionButton}</div>
    </Container>
  );
}

NotificationBar.propTypes = {
  title: PropTypes.string,
  actionButton: PropTypes.object,
  content: PropTypes.string
};

export default NotificationBar;
