import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleAutocomplete from "../SimpleAutocomplete/";
import { AutoCompleteWrapper, Input, Kilometers, Location, Plus, RadiusWrapper } from "../styles";

function renderLocation(suggestion) {
  return <Location>{suggestion.label}</Location>
}

/**
 * Нормализация значений радиуса
 * @param value
 * @returns {number}
 * @private
 */
const radiusNormalize = (value) => {
  if (value < 0) return 0;
  if (value > 7000) return 7000;

  return Number.parseInt(value, 10) || 0;
};

/**
 * input радиуса
 */
const RadiusInput = (props) => {

  const value = radiusNormalize(props.value);
  return (
    <>
      {value >= 0 && <Plus>+</Plus>}
      <Input
        {...props}
        value={value || 0}
        step={1}
      />
      {value >= 0 && <Kilometers>км</Kilometers>}
    </>
  );
};

RadiusInput.propTypes = {
  value: PropTypes.number,
};

class AutocompleteWithRadius extends Component {
  render() {
    return (
      <AutoCompleteWrapper>
        <SimpleAutocomplete
          placeholder={this.props.placeholder}
          onSearch={this.props.onSearch}
          onSelect={this.props.onCityChange}
          value={this.props.autocompleteValue}
          renderOption={renderLocation}
          withIcon={false}
          required={this.props.inputValueRequired}
        />
        {this.props.autocompleteValue && !this.props.radiusDisabled && (
          <RadiusWrapper>
            <RadiusInput
              type="number"
              value={Number.parseInt(this.props.radiusValue, 10) || 0}
              onChange={this.props.onRadiusChange}
              disabled={this.props.radiusDisabled}
              min={this.props.radiusMinMax.min}
              max={this.props.radiusMinMax.max}
              placeholder="Радиус"
            />
          </RadiusWrapper>
        )}
      </AutoCompleteWrapper>
    );
  }
}

AutocompleteWithRadius.propTypes = {
  onCityChange: PropTypes.func,
  onSearch: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  autocompleteValue: PropTypes.object,
  radiusValue: PropTypes.string,
  onRadiusChange: PropTypes.func,
  radiusMinMax: PropTypes.object,
  radiusDisabled: PropTypes.bool,
  radiusAdjacent: PropTypes.bool,
  inputHeight: PropTypes.string,
  inputValueRequired: PropTypes.bool,
};

export default AutocompleteWithRadius;
