import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import _debounce from 'lodash/debounce';
import {AutoSuggestWrap, CommonLocation, IconClear, IconLoading, IconSearch, Input } from '../styles';

const OptionLists = ({label}) => (
    <CommonLocation>{label}</CommonLocation>
);

const getSuggestionValue = (suggestion) => suggestion.label;

class SimpleAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    // оборачиваем событие запроса
    this.debouncedLoadSuggestions = _debounce(
      this.onSuggestionsFetchRequested,
      300
    );
  }

  state = {
    // значение input
    value: (this.props.value && this.props.value.label) || '',

    // список найденных предложений
    suggestions: [],

    // текущее выбранное предложение
    suggestion: this.props.value || null,

    // идет запрос за списком
    isLoading: false
  };

  /**
   *  Меняем state если меняются props
   */
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.suggestion) {
      
      if (props.value === null) {
        return {
          value: '',
          suggestion: null,
        }
      }
      
      return {
        value: props.value.label || '',
        suggestion: props.value || null
      };
    }

    return null;
  }

  /**
   * Переменная для понимания, что идет печать в input
   * нужна для очистки input, если не было выбранно значение из списка
   */
  isTyping = false;

  /**
   * События при изменении input
   */
  onChange = (event, { newValue }) => {
    this.isTyping = true;
    this.setState({
      value: newValue
    });
  };

  /**
   * События при снятии фокуса с input
   */
  onBlur = () => {
    const { onBlur } = this.props;

    // если ничего не выбранно - очищаем значение
    if (!this.state.suggestion || this.isTyping) {
      this.onClearClick(null);

      if (onBlur) {
        onBlur(null);
      }
    }

    if (onBlur) {
      onBlur();
    }
  };

  /**
   * Выбор первого элемента при нажатии Enter
   * @param event
   */
  onKeyDown = (event) => {
    const ENTER_KEY_CODE = 13;
    const { suggestions } = this.state;

    if (event.keyCode === ENTER_KEY_CODE && suggestions.length) {
      this.onSuggestionSelected(event, { suggestion: suggestions[0] });
    }
  };

  /**
   * Событие при очистке выбранного значения
   */
  onClearClick = (event) => {
    this.setState({ value: '' });
    this.onSuggestionSelected(event, { suggestion: null });
  };

  /**
   * Событие при поиске
   */
  onSuggestionsFetchRequested = ({ value }) => {
    const { onSearch } = this.props;

    this.setState({ isLoading: true });

    // onSearch должен возвращать Promise
    onSearch(value).then((suggestions) => {
      this.setState({ suggestions, isLoading: false });
    });
  };

  /**
   * Событие при очистке списка
   */
  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  /**
   * Событие при выборе
   */
  onSuggestionSelected = (event, { suggestion }) => {
    this.isTyping = false;
    const { onSelect } = this.props;
    this.setState({ suggestion });
    onSelect(suggestion);
  };

  shouldRenderSuggestions = (value) => {
    const { minSymbolsForSearch } = this.props;
    return value.trim().length >= minSymbolsForSearch;
  };

  renderInputIcon = () => {
    const { suggestion, isLoading } = this.state;
    const { disabled, withIcon } = this.props;

    if (suggestion && !disabled) {
      return (
        <IconClear
          width="18px"
          height="18px"
          color="onyx"
          onClick={this.onClearClick}
        />
      );
    }

    if (isLoading) {
      return (
        <IconLoading
          width="18px"
          height="18px"
          color="onyx"
        />
      );
    }

    if (withIcon) {
      return (
        <IconSearch
          width="18px"
          height="18px"
          color="onyx"
        />
      );
    }

    return null;
  };

  render() {
    const { placeholder, disabled, renderOption, required } = this.props;
    const { value, suggestions } = this.state;

    const inputProps = {
      ...this.props,
      placeholder,
      value,
      disabled,
      required,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onKeyDown: this.onKeyDown
    };

    delete inputProps.onSelect;
    delete inputProps.onSearch;
    delete inputProps.renderOption;
    delete inputProps.minSymbolsForSearch;
    delete inputProps.withIcon;

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.debouncedLoadSuggestions}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderOption}
        onSuggestionSelected={this.onSuggestionSelected}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        inputProps={inputProps}
        renderInputComponent={(inputProps) => {
          return (
            <AutoSuggestWrap>
              <Input {...inputProps} ref={inputProps.ref} />
              {this.renderInputIcon()}
            </AutoSuggestWrap>
          );
        }}
      />
    );
  }
}



SimpleAutocomplete.defaultProps = {
  value: null,
  minSymbolsForSearch: 1,
  renderOption: OptionLists,
  withIcon: true
};

OptionLists.propTypes = {
  label: PropTypes.string
};

SimpleAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  value: PropTypes.object,
  minSymbolsForSearch: PropTypes.number,
  withIcon: PropTypes.bool,required: PropTypes.bool,

  // input props
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onBlur: PropTypes.func
};

export default SimpleAutocomplete;
