import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import colors from '../../../constants/colors'
import Button from '../../../components/Button'
import moment from 'moment'
import {ButtonWithIcon} from '../../../components/ButtonsCollection'
import IconDownloadFile from '../../../components/Icons/SVGIcons/IconDownloadFile'
import IconSaveTemplate from '../../../components/Icons/SVGIcons/IconSaveAsTemplate'
import {numberWithSpaces} from '../helpers';

const bodyTypes = {
  'awning': 'Тент',
  'isothermal': 'Изотермический',
  'refrigerator': 'Рефрижератор'
}

export const getDirectionHeaderText = (index, from_location, to_location) => {
  const getPlacementText = (location, radius) => {
    let place;
    
    if (location.settlement) {
      place = location.settlement;
    } else if (location.city) {
      place = location.city;
    } else if (location.region) {
      return `${location.region} ${location.region_type}.`;
    } else if (location.area_type) {
      return location.area_type;
    } else {
      place = 'Точка без имени';
    }
    
    return `${place}${radius ? ` + ${radius} км ` : ''}`;
  };
  return `${index} ${getPlacementText(
    from_location.location,
    from_location.radius
  )} → ${getPlacementText(to_location.location, to_location.radius)}`;
};


function Details(props) {
  const { details, reset, editForm } = props;
  const { transport_body_type, from, till, tonnage, volume, routes, rf_only } = details
  
  return (
    <>
        <DetailHeader>
          <Title size="24px">
            { `${
              bodyTypes[transport_body_type]
            }, ${tonnage} т / ${volume} м\u00B3, ${moment(from).format(
              'DD.MM.YY'
            )} — ${moment(till).format('DD.MM.YY')}` }
          </Title>
          <TopButtons>
            <ButtonWithIcon
              color="traffic"
              icon={<IconSaveTemplate width="24" height="24"/>}
              disabled={true}
            >Сохранить шаблон</ButtonWithIcon>
            <ButtonWithIcon
              style={{ marginLeft: 15 }}
              icon={<IconDownloadFile width="24" height="24"/>}
              disabled={true}
            >Скачать отчет в XLS</ButtonWithIcon>
          </TopButtons>
        </DetailHeader>
        <Direction>
          <Title style={{ marginBottom: 15 }} size="24px">
            { rf_only ? "Российская Федерация" : getDirectionHeaderText("", routes[0].from_location, routes[0].to_location) }
          </Title>
          <Text style={{ marginBottom: 15 }}>Стоимость по этому направлению на основании { routes[0].count } перевозок:</Text>
          <PriceContainer>
            <Price>
              <Text style={{ marginBottom: 5 }}>Минимальная, Min</Text>
              <Title size="32px">{ numberWithSpaces(routes[0].min_price) } ₽</Title>
            </Price>
            <Price>
              <Text style={{ marginBottom: 5 }}>Средняя, Avr</Text>
              <Title size="32px">{ numberWithSpaces(routes[0].avg_price) } ₽</Title>
            </Price>
            <Price>
              <Text style={{ marginBottom: 5 }}>Максимальная, Max</Text>
              <Title size="32px">{ numberWithSpaces(routes[0].max_price) } ₽</Title>
            </Price>
          </PriceContainer>
        </Direction>
        <ButtonBlock>
          <Button
            style={{ width: "49%" }}
            text="Редактировать запрос"
            theme="outline"
            size="largest"
            onClick={editForm}
            disabled={routes[0].avg_price === "XXX XXX"}
          />
          <div style={{ width: 50 }}/>
          <Button style={{ width: "49%" }} text="Сбросить фильтры и создать новый запрос" theme="traffic" size="largest" onClick={reset}/>
        </ButtonBlock>
    </>
  )
}

Details.propTypes = {
  details: PropTypes.object,
  reset: PropTypes.func,
  editForm: PropTypes.func,
}

export default Details;


const DetailHeader = styled.div`
  margin: 0 auto 5px auto;
  display: flex;
  justify-content: space-between;
  max-width: 1170px;
  background-color: white;
  padding: 25px 30px;
  box-shadow: ${colors.boxShadowModal};
`

const TopButtons = styled.div`
  display: flex;
`

const Title = styled.div`
  font-size: ${ props => props.size};
  color: ${colors.coal};
  font-family: GTPressuraProBold, sans-serif;
  line-height: 1.25;
`

const Text = styled.div`
  color: ${colors.coal};
  opacity: 0.8;
  font-size: 16px;
  line-height: 1.25;
`

const Direction = styled.div`
  margin: 0 auto;
  max-width: 1170px;
  background-color: white;
  padding: 30px;
  box-shadow: ${colors.boxShadowModal};
`

const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Price = styled.div`
  width: 32.5%;
  background-color: ${colors.smoke};
  padding: 20px 40px;
`

const ButtonBlock = styled.div`
  max-width: 1170px;
  margin: 25px auto;
  display: flex;
  justify-content: space-between;
`
