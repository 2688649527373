import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../../constants/colors'
import Button from '../../../components/Button'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors.smoke};
  padding: 70px 0 100px 0;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 770px;
`

const Title = styled.div`
  font-size: 48px;
  font-family: GTPressuraProBold, sans-serif;
  color: ${colors.coal};
  line-height: 1.25;
  margin-bottom: 40px;
  text-align: center;
`

function TitleWithButton(props) {
  const { onAction } = props;
  return (
    <Wrapper>
      <Container>
        <Title>Узнайте рыночную стоимость каждой вашей перевозки</Title>
        <Button size="largest" onClick={onAction} text="Сформировать запрос для расчета рыночных цен"/>
      </Container>
    </Wrapper>
  )
}

TitleWithButton.propTypes = {
  onAction: PropTypes.func
};

export default TitleWithButton;
