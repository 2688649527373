import React from 'react';
import SVGIcon from './index';
import PropTypes from 'prop-types';
import colors from '../../../constants/colors';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  100% {
    transform:rotate(360deg);
  }
`;

const Icon = styled(SVGIcon)`
  animation: ${rotate} 1440ms linear infinite;
  transform-origin: center center;

  path {
    fill-rule: evenOdd;
  }
`

const IconLoading = (props) => {
  return (
    <Icon {...props} viewBox="0 0 128 128">
      <linearGradient id="linear-gradient">
        <stop offset="0%" stopColor={colors.snow} fillOpacity="0" />
        <stop offset="100%" stopColor={colors[props.color]} fillOpacity="1" />
      </linearGradient>
      <g>
        <path
          d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
          fill="url(#linear-gradient)"
        />
      </g>
    </Icon>
  );
};

IconLoading.propTypes = {
  color: PropTypes.string
};

export default IconLoading;
